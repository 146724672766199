<template>
  <b-modal id="view-modal" hide-footer  hide-header no-close-on-backdrop size="md" style="top:30%;" class="text-center">
    <div class="d-flex flex-column text-center justify-content-center"> 
      <h6>View {{ label }}</h6>

      <div v-if="state == 'otp'" class="main-wrapper">
         <form v-on:submit.prevent="verifyOtp">
        <div class="otp-wrapper" >
          <div>
            <input id="otp1" type="text" autocomplete="off" maxlength="1" v-model="otpArr[0]" class="otp-input otp-input-100" @keydown="otpBack($event,1)" @keypress="isNumber($event)">
          </div>

          <div>
            <input id="otp2"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[1]" class="otp-input otp-input-100" @keydown="otpBack($event,2)" @keypress="isNumber($event)">
          </div>

          <div>
            <input id="otp3" type="text" autocomplete="off" maxlength="1" v-model="otpArr[2]" class="otp-input otp-input-100" @keydown="otpBack($event,3)" @keypress="isNumber($event)">
          </div>

          <div>
            <input id="otp4"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[3]" class="otp-input otp-input-100" @keydown="otpBack($event,4)" @keypress="isNumber($event)">
          </div>

          <div>
            <input id="otp5"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[4]" class="otp-input otp-input-100" @keydown="otpBack($event,5)" @keypress="isNumber($event)">
          </div>

          <div>
            <input id="otp6"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[5]" class="otp-input otp-input-100" @keydown="otpBack($event,6)" @keypress="isNumber($event)">
          </div>
        </div>
        
        <p class="help-block" v-if="errors.otp && errors.otp !== ''"> {{ errors.otp }} </p> 

         <div class="form-group ">
            <button type="submit" class="btn btn-main"> Submit </button>
        </div>

      </form>

      </div>
      <div v-if="state == 'done'">
        <img :src="`data:image/png;base64,${asset}`" class="mx-auto" style="width: 300px;"/>

         <button class="btn btn-main mx-auto" @click="close">CLOSE</button>
      </div>
      
     
    </div>
  </b-modal>
</template>

<script>

import qs from 'querystring' 
import numeral from 'numeral' 
export default {
  name: 'ExportImportFund',
  props: ["asset", "label"],
  components: {
  },
  data: function() {
      return  {
        state: 'otp',
         otpArr: [], 
         errors: {
          otp: ""
         }
      }
    },
    computed: {
      init() {
          return this.$store.state.init;
      },
      
    },
    methods: {
      verifyOtp() { 

         let otp = this.otpArr[0] + this.otpArr[1] + this.otpArr[2] + this.otpArr[3] + this.otpArr[4] + this.otpArr[5];

        const params = {
                key: this.$route.params.key,
                otp: otp,
            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');


            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/chain/twofa/verify',
            };

            this.axios(options)
                .then(() => { 
                    this.state = 'done'
                }).catch((err) => {
                    this.errors.otp = 'Incorect OTP';
                })

      },
      requestTwo() {
        const params = {
                key: this.$route.params.key
            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');


            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/chain/twofa',
            };

            this.axios(options)
                .then(() => { 
                    
                }).catch((err) => {
                    console.log(err) 
                })

      },
     close: function(){
          this.$bvModal.hide('view-modal'); 
      },
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      otpBack: function(event, currrentBox) {

        if (event.keyCode === 8) {
              if (currrentBox != 1) {

                  setTimeout(function() { 
                      document.getElementById("otp"+ (currrentBox - 1)).focus();
                  }, 50)

              }
          }

          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {

              setTimeout(function() { 

                  if((currrentBox) < 6) {
                    document.getElementById("otp"+ (currrentBox + 1)).focus();
                  }
                  
              }, 50)

          }
      },
    },
    mounted(){
      this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
        this.requestTwo();
      })
      
    },
}
</script>
 
<style scoped lang="scss">
  .main-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

   .otp-wrapper {
      display: flex;
      width: 80%;
      justify-content: space-between;
      margin-bottom: 1em;
      div {
      margin: 0 .25em;

      .otp-input {
              width: 47px;
              text-align: center;
              border-radius: 6px;
              outline: none;
              box-shadow: none;
              font-size: 2em; 
              border: 2px black solid;
              box-shadow: none;
              outline: none; 
              color: #262d33;
              border-radius: 6px;
              height: 60px;
      }
    }
  }

  button {
    margin-top:1em;
  }

   .error {
    color: red;
   }

   p {
     margin-top:0px;
     padding-top:0px;
   }

   h5 {
     font-weight:bold;
     margin-top:5px;
   }

   .form-control {
     background-color: #ccc;
   }

  .email-or-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1em;

      .btn {
        color: #47525b;
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 0;
        &.active {
          color: #d8962c;
          border-bottom: 1px solid #d8962c;
        }
      }
 
    }

  .btn-main {
   width:40%;
 }

  @media screen and (max-width:1000px) {
    div {
      font-size:0.86em;
    }

    div h5 {
      font-size:1em;
    }
  }
  
</style>